@font-face {
  font-family: "tt_commons";
  src: url("../fonts/tt_commons/tt_commons_regular-webfont.woff2")
      format("woff2"),
    url("../fonts/tt_commons/tt_commons_regular-webfont.woff")
      format("woff");

      /* ../fonts/tt_commons/tt_commons_regular-webfont.woff2 */
}




  body {
    font-family: "Manrope", sans-serif;
  }
  .gradient {
    background: linear-gradient(334deg, #032d3a, #032d3a, #323204);
    background-size: 180% 180%;
    animation: gradient-animation 6s ease infinite;
  }
  .gradient-white {
    background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%23032D3A'/%3E%3Cstop offset='1' stop-color='%23E9FFBF'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23032D3A'/%3E%3Cstop offset='1' stop-color='%23D099D9'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='' cx='500' cy='100' r='40'/%3E%3Cpath transform='' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  }
  

  .card-hover-effect :hover{
    background-color: #032D3A;
    color: "white";  
    transition: background-position 0.3s ease;

  }

  .footer-container {
    max-width: 1170px;
    margin: auto;
  }
  .footer-row {
    display: flex;
    flex-wrap: wrap;
  }
  ul {
    list-style: none;
  }
  .footer {
    background-color: #051d23;
    padding: 70px 0;
    margin-top: 100px;
  }
  .footer-col {
    width: 25%;
    padding: 0 15px;
  }
  .footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
  }
  
  .footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
  }
  .footer-col ul li a {
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
  }
  .footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 8px;
  }
  .footer-col .social-links {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
  }
  .footer-col .social-links a:hover {
    color: #051d23;
    background-color: #ffffff;
  }
  
  .footer-social-icons {
    font-size: 35px;
    color: white;
  }
  
  /*responsive*/
  @media (max-width: 767px) {
    .footer-col {
      width: 50%;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 574px) {
    .footer-col {
      width: 100%;
    }
  }
  
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  


  @keyframes header-gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

  .customButton{
    background-color: #0c8924; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
  
  
  .primary_color_main {
    color: "#2065D1" !important;
  }
  
  .unauth-navbar {
    background-color: transparent;
    box-shadow: none;
    z-index: 1100;
    color: rgb(33, 43, 54);
    font-size: 0.875rem;
  }
  
  .unauth-navbar-scrolled {
    background-color: #ffffffb3;
    backdrop-filter: blur(6px);
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(33, 43, 54);
    font-size: 0.875rem;
  }
  
  
  .left-sidebar-grid{
    border-left: solid #fcfcfc;
  }

  .right-card {
    margin-left: auto;
  }
  
  .left-card {
    margin-right: auto;
  }


  ::-webkit-scrollbar {
    width: 6px;
   
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ececec; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }



  .chat {
    border-radius: 0.25rem;
    font-family: "SanFrancisco";
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
  
  .chat p {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
  }
  
  .chat p::before,
  .chat p::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }
  






  
  p.from-me {
    align-self: flex-end;
    background-color: #248bf5;
    color: #fff; 
  }
  
  p.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #248bf5;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }
  
  p.from-me::after {
    background-color: #f4f4f4;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform:translate(-30px, -2px);
    width: 10px;
  }
  
  p[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
  }
  
  p.from-me ~ p.from-me {
    margin: 0.25rem 0 0;
  }
  
  p.from-me ~ p.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
  }
  
  p.from-me ~ p.from-me:last-child {
    margin-bottom: 0.5rem;
  }
  
  p.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
  }
  
  p.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }
  
  p.from-them::after {
    background-color: #f4f4f4;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }
  
  

  .text-img-container{
    position: relative;
    text-align: center;
    color: white;
  }
  
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  
  
  
  
  
  
  /* some colors */
  .color-black {
    color: black
  }
  
  .color-white {
    color: white
  }
  
  .bg-black {
    background-color: black
  }
  
  .bg-white {
    background-color: white
  }
  
  
  
  
  
  /* GENERIC CSS */
  
  .pointer {
    cursor: pointer;
  }
  
  .hide {
    display: none;
  }
  
  .show {
    display: block;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-end{
    text-align: end;
  }
  
  
  .module-player-container{
    height: 83vh;
  }
  
  /* Flex */
  .flex {
    display: flex;
  }
  
  .row {
    flex-direction: row;
  }
  
  .column {
    flex-direction: column;
  }
  
  .justify-start {
    justify-content: flex-start;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .justify-end {
    justify-content: flex-end;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  .align-start {
    align-items: flex-start;
  }
  
  .align-center {
    align-items: center;
  }
  
  .align-end {
    align-items: flex-end;
  }
  
  .rounded {
    border-radius: 4px;
  }
  
  .rounded-sm {
    border-radius: 2px;
  }
  
  .rounded-lg {
    border-radius: 8px;
  }
  
  .rounded-circle {
    border-radius: 50%;
  }
  
  .rounded-pill {
    border-radius: 50rem;
  }
  
  .rounded-0 {
    border-radius: 0;
  }
  
  
  
  .font-weight-100 {
    font-weight: 100;
  }
  
  .font-weight-200 {
    font-weight: 200;
  }
  
  .font-weight-300 {
    font-weight: 300;
  }
  
  .font-weight-400 {
    font-weight: 400;
  }
  
  .font-weight-500 {
    font-weight: 500;
  }
  
  .font-weight-600 {
    font-weight: 600;
  }
  
  .font-weight-700 {
    font-weight: 700;
  }
  
  .font-weight-800 {
    font-weight: 800;
  }
  
  .font-weight-900 {
    font-weight: 900;
  }
  
  .no-text-decoration {
    text-decoration: none;
  }
  
  /* margin left */
  .ml-0 {
    margin-left: 0px;
  }
  
  .ml-1 {
    margin-left: 5px;
  }
  
  .ml-2 {
    margin-left: 10px;
  }
  
  .ml-3 {
    margin-left: 15px;
  }
  
  .ml-4 {
    margin-left: 20px;
  }
  
  .ml-5 {
    margin-left: 25px;
  }
  
  .ml-6 {
    margin-left: 30px;
  }
  
  .ml-7 {
    margin-left: 35px;
  }
  
  .ml-8 {
    margin-left: 40px;
  }
  
  .ml-9 {
    margin-left: 45px;
  }
  
  .ml-10 {
    margin-left: 50px;
  }
  
  .mr-0 {
    margin-right: 0px;
  }
  
  .mr-1 {
    margin-right: 5px;
  }
  
  .mr-2 {
    margin-right: 10px;
  }
  
  .mr-3 {
    margin-right: 15px;
  }
  
  .mr-4 {
    margin-right: 20px;
  }
  
  .mr-5 {
    margin-right: 25px;
  }
  
  .mr-6 {
    margin-right: 30px;
  }
  
  .mr-7 {
    margin-right: 35px;
  }
  
  .mr-8 {
    margin-right: 40px;
  }
  
  .mr-9 {
    margin-right: 45px;
  }
  
  .mr-10 {
    margin-right: 50px;
  }
  
  .mt-0 {
    margin-top: 0px;
  }
  
  .mt-1 {
    margin-top: 5px;
  }
  
  .mt-2 {
    margin-top: 10px;
  }
  
  .mt-3 {
    margin-top: 15px;
  }
  
  .mt-4 {
    margin-top: 20px;
  }
  
  .mt-5 {
    margin-top: 25px;
  }
  
  .mt-6 {
    margin-top: 30px;
  }
  
  .mt-7 {
    margin-top: 35px;
  }
  
  .mt-8 {
    margin-top: 40px;
  }
  
  .mt-9 {
    margin-top: 45px;
  }
  
  .mt-10 {
    margin-top: 50px;
  }
  
  /* Paddings */
  
  .mb-0 {
    margin-bottom: 0px;
  }
  
  .mb-1 {
    margin-bottom: 5px;
  }
  
  .mb-2 {
    margin-bottom: 10px;
  }
  
  .mb-3 {
    margin-bottom: 15px;
  }
  
  .mb-4 {
    margin-bottom: 20px;
  }
  
  .mb-5 {
    margin-bottom: 25px;
  }
  
  .mb-6 {
    margin-bottom: 30px;
  }
  
  .mb-7 {
    margin-bottom: 35px;
  }
  
  .mb-8 {
    margin-bottom: 40px;
  }
  
  .mb-9 {
    margin-bottom: 45px;
  }
  
  .mb-10 {
    margin-bottom: 50px;
  }
  
  .pl-0 {
    padding-left: 0px;
  }
  
  .pl-1 {
    padding-left: 5px;
  }
  
  .pl-2 {
    padding-left: 10px;
  }
  
  .pl-3 {
    padding-left: 15px;
  }
  
  .pl-4 {
    padding-left: 20px;
  }
  
  .pl-5 {
    padding-left: 25px;
  }
  
  .pl-6 {
    padding-left: 30px;
  }
  .pl-7 {
    padding-left: 35px;
  }
  .pl-8 {
    padding-left: 40px;
  }
  .pl-9 {
    padding-left: 45px;
  }
  .pl-10 {
    padding-left: 50px !important;
  }
  
  .pr-0 {
    padding-right: 0px;
  }
  
  .pr-1 {
    padding-right: 5px;
  }
  
  .pr-2 {
    padding-right: 10px;
  }
  
  .pr-3 {
    padding-right: 15px;
  }
  
  .pr-4 {
    padding-right: 20px;
  }
  
  .pr-5 {
    padding-right: 25px;
  }
  .pr-6 {
    padding-right: 30px;
  }
  
  .pr-7 {
    padding-right: 35px;
  }
  
  .pr-8 {
    padding-right: 40px;
  }
  
  .pr-9 {
    padding-right: 45px;
  }
  
  .pr-10 {
    padding-right: 50px;
  }
  
  
  .pt-0 {
    padding-top: 0px;
  }
  
  .pt-1 {
    padding-top: 5px;
  }
  
  .pt-2 {
    padding-top: 10px;
  }
  
  .pt-3 {
    padding-top: 15px;
  }
  
  /* From 11-20 */
  .mt-11 {
    margin-top: 55px;
  }
  
  .mt-12 {
    margin-top: 60px;
  }
  
  .mt-13 {
    margin-top: 65px;
  }
  
  .mt-14 {
    margin-top: 70px;
  }
  
  .mt-15 {
    margin-top: 75px;
  }
  
  .mt-16 {
    margin-top: 80px;
  }
  
  .mt-17 {
    margin-top: 85px;
  }
  
  .mt-18 {
    margin-top: 90px;
  }
  
  .mt-19 {
    margin-top: 95px;
  }
  
  .mt-20 {
    margin-top: 100px;
  }
  
  .mb-11 {
    margin-bottom: 55px;
  }
  
  .mb-12 {
    margin-bottom: 60px;
  }
  
  .mb-13 {
    margin-bottom: 65px;
  }
  
  .mb-14 {
    margin-bottom: 70px;
  }
  
  .mb-15 {
    margin-bottom: 75px;
  }
  
  .mb-16 {
    margin-bottom: 80px;
  }
  
  .mb-17 {
    margin-bottom: 85px;
  }
  
  .mb-18 {
    margin-bottom: 90px;
  }
  
  .mb-19 {
    margin-bottom: 95px;
  }
  
  .mb-20 {
    margin-bottom: 100px;
  }
  
  .ml-11 {
    margin-left: 55px;
  }
  
  .ml-12 {
    margin-left: 60px;
  }
  
  .ml-13 {
    margin-left: 65px;
  }
  
  .ml-14 {
    margin-left: 70px;
  }
  
  .ml-15 {
    margin-left: 75px;
  }
  
  .ml-16 {
    margin-left: 80px;
  }
  
  .ml-17 {
    margin-left: 85px;
  }
  
  .ml-18 {
    margin-left: 90px;
  }
  
  .ml-19 {
    margin-left: 95px;
  }
  
  .ml-20 {
    margin-left: 100px;
  }
  
  .mr-11 {
    margin-right: 55px;
  }
  
  .mr-12 {
    margin-right: 60px;
  }
  
  .mr-13 {
    margin-right: 65px;
  }
  
  .mr-14 {
    margin-right: 70px;
  }
  
  .mr-15 {
    margin-right: 75px;
  }
  
  .mr-16 {
    margin-right: 80px;
  }
  
  .mr-17 {
    margin-right: 85px;
  }
  
  .mr-18 {
    margin-right: 90px;
  }
  
  .mr-19 {
    margin-right: 95px;
  }
  
  .mr-20 {
    margin-right: 100px;
  }
  
  .m-1 {
    margin: 5px;
  }
  
  .m-2 {
    margin: 10px;
  }
  
  .m-3 {
    margin: 15px;
  }
  
  .m-4 {
    margin: 20px;
  }
  
  .m-5 {
    margin: 25px;
  }
  
  .m-6 {
    margin: 30px;
  }
  
  .m-7 {
    margin: 35px;
  }
  
  .m-8 {
    margin: 40px;
  }
  
  .m-9 {
    margin: 45px;
  }
  
  .m-10 {
    margin: 50px;
  }
  
  .p-1 {
    padding: 5px;
  }
  
  .p-2 {
    padding: 10px;
  }
  
  .p-3 {
    padding: 15px;
  }
  
  .p-4 {
    padding: 20px;
  }
  
  .p-5 {
    padding: 25px;
  }
  
  .p-6 {
    padding: 30px;
  }
  
  .p-7 {
    padding: 35px;
  }
  
  .p-8 {
    padding: 40px;
  }
  
  .p-9 {
    padding: 45px;
  }
  
  .p-10 {
    padding: 50px;
  }